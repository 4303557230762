import React, { useEffect, useState } from "react";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  resetFormData,
  setFormData,
  setFormSubmit,
} from "../Store/Slice/surveyDataSlice";

const SubmitPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formData = useSelector((state) => state.surveyData?.formData);
  const reduxFormSubmit = useSelector(
    (state) => state.surveyData?.isFormSubmit
  );

  const [formsubmitted, setFormSubmitted] = useState(false);
  const [suggestions, setSuggestions] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (reduxFormSubmit) {
      setFormSubmitted(reduxFormSubmit);
    }
  }, []);

  const handleSubmit = async () => {
    setLoader(true)
    if (formData) {
      let payload = {
        citizenName: formData.applicantName.trim(),
        citizenEmail: "test@gmail.com",
        citizenMobileNo: formData.applicantMobile.trim(),
        citizenGender: formData.applicantgender,
        citizenAge: formData.applicantAge,
        nameOfZoneSugtoWork: formData.suggestedzone,
        nameOfWardSugtoWork: formData.suggestedward[0].wardvalue,
        wardNoSugtoWork: formData.suggestedward[0].wardvalue,
        nameOfArea: formData.suggestedarea[0].area_value,
        SuggestedWorkVerticle: formData.selectedVertical,
        verticleOptions: formData.selectedVericalOption,
        speecifyWork: formData.specifyWork,
        citizenSuggestion: suggestions,
        ammenityOptions: formData.ammenityOptions,
      };

      await fetch(
        process.env.REACT_APP_BASE_URL + "/user/v1/createParticipateBudgeting",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      )
        .then((res) => res.json())
        .then((response) => {
          if (response.code === 200) {
            setLoader(false);
            // dispatch(setFormData({}));
            dispatch(setFormSubmit(true));
            setFormSubmitted(true);
          }else if(response.code === 1111){
            dispatch(setFormSubmit(true));
            setFormSubmitted(true);
            setLoader(false);
            navigate("/max-submit");
          }
        })
        .catch((Err) => {
          console.log("Err while getting dashcards", Err);
          setLoader(false);
        });
    }
  };

  const handleNewForm = () => {
    dispatch(resetFormData({}));
    dispatch(setFormSubmit(false));
    setFormSubmitted(false);
    navigate("/survey-page");
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      {formsubmitted ? (
        <div className="button-container">
          <h6>Thank You for your suggestion. You can submit up to three responses !</h6>
          <h6>तुमच्या सूचनेबद्दल धन्यवाद. तुम्ही जास्तीत जास्त तीन प्रतिसाद सबमिट करू शकता !</h6>
          <Button
            className="submit-button mt-3 me-2"
            onClick={() => {
              handleNewForm();
            }}
          >
            Submit
          </Button>
        </div>
      ) : (
        <div className="form-container">
          <Row className="mt-5">
            <Form.Group controlId="formBasicDescription">
              <Form.Label className="work-label">
                Mention here if you have suggestions from other categories
                (don't forget to metion location and landmark)
              </Form.Label>
              <Form.Label className="work-label">
                तुमच्या इतर श्रेणीतील सूचना असतील तर येथे नमूद करा
                (स्थान/लँडमार्क नमूद करायला विसरू नका)
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={suggestions}
                onChange={(e) => setSuggestions(e.target.value)}
              />
            </Form.Group>
          </Row>

          <center>
            {loader ? (
              <Spinner />
            ) : (
              <>
                <Button
                  className="submit-button mt-3 me-2"
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  Prev
                </Button>
                <Button
                  className="submit-button mt-3 me-2"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Submit
                </Button>
              </>
            )}
          </center>
        </div>
      )}
    </div>
  );
};

export default SubmitPage;
