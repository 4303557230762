import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./common/ScrollToTop";
import InitialSurvey from "./pages/InitialSurvey";
import VerticalDataPage from "./pages/VerticalDataPage";
import SubmitPage from "./pages/SubmitPage";
import SurveyLayout from "./Layout/SurveyLayout";
import MaxLimit from "./pages/MaxLimit";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer />
        <ScrollToTop />
        <Routes>
        <Route path="/" element={<Navigate to="/survey-page" />} />
          <Route path="/" element={<SurveyLayout />}>
            <Route index path="/survey-page" element={<InitialSurvey />} />
            <Route path="/vertical-form" element={<VerticalDataPage />} />
            <Route path="/survey-submit" element={<SubmitPage />} />
            <Route path="/max-submit" element={<MaxLimit />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
