import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";
import PCMC from "../assests/images/pcmc-official-logo.png";
import SmartCity from "../assests/images/smart-city-pc-logo.png";
import SmartSarthi from "../assests/images/smart-sarthi-logo.png";

const SurveyLayout = () => {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   navigate("/survey-page");
  // }, []);

  return (
    <div>
      <Container fluid>
        <div className="logo-div">
          <img src={PCMC} className="logo-image" />
          <img src={SmartSarthi} className="logo-image" />
          <img src={SmartCity} className="logo-image" />
        </div>
        <div className="d-flex justify-content-center align-items-center">
        <div className="header-container">
          <h3 style={{ color: "#14c16f", fontWeight: 700 }}>
            Citizen Participatory Budgeting (2025-2026)
          </h3>
          <h3 style={{ color: "#14c16f", fontWeight: 700 }}>
            अर्थसंकल्पात नागरिकांचा सहभाग (२०२५-२०२६ )
          </h3>
        </div>
        </div>
        <div className="outlet-div">
          <Outlet />
        </div>
      </Container>
    </div>
  );
};

export default SurveyLayout;
