import React, { useEffect, useState } from "react";
import { Form, Button, Container, Col, Row, Dropdown } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFormData } from "../Store/Slice/surveyDataSlice";

const InitialSurvey = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formData = useSelector((state) => state.surveyData?.formData);
  const reduxFormSubmit = useSelector(
    (state) => state.surveyData?.isFormSubmit
  );

  const [name, setName] = useState("");
  const [mobile, setmobile] = useState("");
  const [gender, setgender] = useState("");
  const [age, setage] = useState("");
  const [zone, setzone] = useState("");
  const [ward, setward] = useState([]);
  const [wardName, setWardName] = useState("");
  const [area, setarea] = useState([]);
  const [selectedVertical, setSelectedVertical] = useState("");
  const [surveyError, setSurveyError] = useState({});
  const [limitLoader, setLimitLoader] = useState(false);

  const zonesDrop = [
    { label: "Zone A/झोन अ", value: "A" },
    { label: "Zone B/झोन ब", value: "B" },
    { label: "Zone C/ झोन क", value: "C" },
    { label: "Zone D/ झोन ड", value: "D" },
    { label: "Zone E/ झोन ई", value: "E" },
    { label: "Zone F/ झोन फ", value: "F" },
    { label: "Zone G/ झोन ग ", value: "G" },
    { label: "Zone H/ झोन ह ", value: "H" },
  ];

  const wardDrop = [
    {
      zone: "A",
      wardno: "10",
      wardvalue: "10",
    },
    {
      zone: "A",
      wardno: "14",
      wardvalue: "14",
    },
    {
      zone: "A",
      wardno: "15",
      wardvalue: "15",
    },
    {
      zone: "A",
      wardno: "19",
      wardvalue: "19",
    },

    {
      zone: "B",
      wardno: "16",
      wardvalue: "16",
    },
    {
      zone: "B",
      wardno: "17",
      wardvalue: "17",
    },
    {
      zone: "B",
      wardno: "18",
      wardvalue: "18",
    },
    {
      zone: "B",
      wardno: "22",
      wardvalue: "22",
    },

    {
      zone: "C",
      wardno: "2",
      wardvalue: "2",
    },
    {
      zone: "C",
      wardno: "6",
      wardvalue: "6",
    },
    {
      zone: "C",
      wardno: "8",
      wardvalue: "8",
    },
    {
      zone: "C",
      wardno: "9",
      wardvalue: "9",
    },

    {
      zone: "D",
      wardno: "25",
      wardvalue: "25",
    },
    {
      zone: "D",
      wardno: "26",
      wardvalue: "26",
    },
    {
      zone: "D",
      wardno: "28",
      wardvalue: "28",
    },
    {
      zone: "D",
      wardno: "29",
      wardvalue: "29",
    },

    {
      zone: "E",
      wardno: "3",
      wardvalue: "3",
    },
    {
      zone: "E",
      wardno: "4",
      wardvalue: "4",
    },
    {
      zone: "E",
      wardno: "5",
      wardvalue: "5",
    },
    {
      zone: "E",
      wardno: "7",
      wardvalue: "7",
    },

    {
      zone: "F",
      wardno: "1",
      wardvalue: "1",
    },
    {
      zone: "F",
      wardno: "11",
      wardvalue: "11",
    },
    {
      zone: "F",
      wardno: "12",
      wardvalue: "12",
    },
    {
      zone: "F",
      wardno: "13",
      wardvalue: "13",
    },

    {
      zone: "G",
      wardno: "21",
      wardvalue: "21",
    },
    {
      zone: "G",
      wardno: "23",
      wardvalue: "23",
    },
    {
      zone: "G",
      wardno: "24",
      wardvalue: "24",
    },
    {
      zone: "G",
      wardno: "27",
      wardvalue: "27",
    },

    {
      zone: "H",
      wardno: "20",
      wardvalue: "20",
    },
    {
      zone: "H",
      wardno: "30",
      wardvalue: "30",
    },
    {
      zone: "H",
      wardno: "31",
      wardvalue: "31",
    },
    {
      zone: "H",
      wardno: "32",
      wardvalue: "32",
    },
  ];

  const areasDrop = [
    {
      area_name: "Vishal Theater Area",
      area_value: "Vishal Theater Area",
      ward_name: "20",
    },
    {
      area_name: "H.A. Colony",
      area_value: "H.A. Colony",
      ward_name: "20",
    },
    {
      area_name: "Mahesh Nagar",
      area_value: "Mahesh Nagar",
      ward_name: "20",
    },
    {
      area_name: "Sant Tukaram Nagar",
      area_value: "Sant Tukaram Nagar",
      ward_name: "20",
    },
    {
      area_name: "Mahatma Phule Nagar",
      area_value: "Mahatma Phule Nagar",
      ward_name: "20",
    },
    {
      area_name: "Vallabh Nagar",
      area_value: "Vallabh Nagar",
      ward_name: "20",
    },
    {
      area_name: "Landewadi slum",
      area_value: "Landewadi slum",
      ward_name: "20",
    },
    {
      area_name: "CIRT",
      area_value: "CIRT",
      ward_name: "20",
    },
    {
      area_name: "Parshvnath Society",
      area_value: "Parshvnath Society",
      ward_name: "20",
    },
    {
      area_name: "Kasarwadi part",
      area_value: "Kasarwadi part",
      ward_name: "20",
    },
    {
      area_name: "Agrasen Nagar",
      area_value: "Agrasen Nagar",
      ward_name: "20",
    },
    {
      area_name: "Kundan Nagar part",
      area_value: "Kundan Nagar part",
      ward_name: "20",
    },
    {
      area_name: "Shankarwadi Part",
      area_value: "Shankarwadi Part",
      ward_name: "30",
    },
    {
      area_name: "Sarita Sangam Society",
      area_value: "Sarita Sangam Society",
      ward_name: "30",
    },
    {
      area_name: "Shastri Nagar",
      area_value: "Shastri Nagar",
      ward_name: "30",
    },
    {
      area_name: "Keshv Nagar",
      area_value: "Keshv Nagar",
      ward_name: "30",
    },
    {
      area_name: "Kasarwadi part",
      area_value: "Kasarwadi part",
      ward_name: "30",
    },
    {
      area_name: "Kundan Nagar part",
      area_value: "Kundan Nagar part",
      ward_name: "30",
    },
    {
      area_name: "Phugewadi",
      area_value: "Phugewadi",
      ward_name: "30",
    },
    {
      area_name: "Sanjay Nagar",
      area_value: "Sanjay Nagar",
      ward_name: "30",
    },
    {
      area_name: "Dapodi",
      area_value: "Dapodi",
      ward_name: "30",
    },
    {
      area_name: "Siddharth Nagar",
      area_value: "Siddharth Nagar",
      ward_name: "30",
    },
    {
      area_name: "Ganesh Nagar",
      area_value: "Ganesh Nagar",
      ward_name: "30",
    },
    {
      area_name: "Sunderbagh Colony",
      area_value: "Sunderbagh Colony",
      ward_name: "30",
    },
    {
      area_name: "ST Workshop",
      area_value: "ST Workshop",
      ward_name: "30",
    },
    {
      area_name: "Part-1 Rajiv Gandhi Nagar",
      area_value: "Part-1 Rajiv Gandhi Nagar",
      ward_name: "31",
    },
    {
      area_name: "Gajanan Maharaj Nagar",
      area_value: "Gajanan Maharaj Nagar",
      ward_name: "31",
    },
    {
      area_name: "Kirti Nagar",
      area_value: "Kirti Nagar",
      ward_name: "31",
    },
    {
      area_name: "Vinayak Nagar",
      area_value: "Vinayak Nagar",
      ward_name: "31",
    },
    {
      area_name: "Ganesh Nagar",
      area_value: "Ganesh Nagar",
      ward_name: "31",
    },
    {
      area_name: "Kavde Nagar",
      area_value: "Kavde Nagar",
      ward_name: "31",
    },
    {
      area_name: "Gagarde Nagar part",
      area_value: "Gagarde Nagar part",
      ward_name: "31",
    },
    {
      area_name: "Vidya Nagar part Part II Uro Hospital",
      area_value: "Vidya Nagar part Part II Uro Hospital",
      ward_name: "31",
    },
    {
      area_name: "Sangavi Gaothan",
      area_value: "Sangavi Gaothan",
      ward_name: "32",
    },
    {
      area_name: "Madhuban Society",
      area_value: "Madhuban Society",
      ward_name: "32",
    },
    {
      area_name: "Dhore Nagar",
      area_value: "Dhore Nagar",
      ward_name: "32",
    },
    {
      area_name: "Jaymala Nagar",
      area_value: "Jaymala Nagar",
      ward_name: "32",
    },
    {
      area_name: "Sangam Nagar",
      area_value: "Sangam Nagar",
      ward_name: "32",
    },
    {
      area_name: "PWD Colony",
      area_value: "PWD Colony",
      ward_name: "32",
    },
    {
      area_name: "ST Colony",
      area_value: "ST Colony",
      ward_name: "32",
    },
    {
      area_name: "Krishna Nagar",
      area_value: "Krishna Nagar",
      ward_name: "32",
    },
    {
      area_name: "Sairaj Residency",
      area_value: "Sairaj Residency",
      ward_name: "32",
    },
    {
      area_name: "Shivdatt Nagar",
      area_value: "Shivdatt Nagar",
      ward_name: "32",
    },
    {
      area_name: "Milind Nagar",
      area_value: "Milind Nagar",
      ward_name: "21",
    },
    {
      area_name: "Subhash Nagar",
      area_value: "Subhash Nagar",
      ward_name: "21",
    },
    {
      area_name: "Gautam Nagar",
      area_value: "Gautam Nagar",
      ward_name: "21",
    },
    {
      area_name: "Adarash Nagar",
      area_value: "Adarash Nagar",
      ward_name: "21",
    },
    {
      area_name: "Indira Nagar",
      area_value: "Indira Nagar",
      ward_name: "21",
    },
    {
      area_name: "Shastri Nagar",
      area_value: "Shastri Nagar",
      ward_name: "21",
    },
    {
      area_name: "Baldev Nagar",
      area_value: "Baldev Nagar",
      ward_name: "21",
    },
    {
      area_name: "Ganesh Nagar",
      area_value: "Ganesh Nagar",
      ward_name: "21",
    },
    {
      area_name: "Jijamata Hospital",
      area_value: "Jijamata Hospital",
      ward_name: "21",
    },
    {
      area_name: "Sanjay Gandhi Nagar",
      area_value: "Sanjay Gandhi Nagar",
      ward_name: "21",
    },
    {
      area_name: "Vaibhav Nagar",
      area_value: "Vaibhav Nagar",
      ward_name: "21",
    },
    {
      area_name: "Ashok Theater",
      area_value: "Ashok Theater",
      ward_name: "21",
    },
    {
      area_name: "Vaishno Devi Temple",
      area_value: "Vaishno Devi Temple",
      ward_name: "21",
    },
    {
      area_name: "Masulkar Park",
      area_value: "Masulkar Park",
      ward_name: "21",
    },
    {
      area_name: "Pimprigaon",
      area_value: "Pimprigaon",
      ward_name: "21",
    },
    {
      area_name: "Tapovan Temple",
      area_value: "Tapovan Temple",
      ward_name: "21",
    },
    {
      area_name: "Balmal Chal",
      area_value: "Balmal Chal",
      ward_name: "21",
    },
    {
      area_name: "Kailas Nagar",
      area_value: "Kailas Nagar",
      ward_name: "21",
    },
    {
      area_name: "Dnyaneshwar Nagar",
      area_value: "Dnyaneshwar Nagar",
      ward_name: "21",
    },
    {
      area_name: "Dr. Babasaheb Ambedkar Colony part",
      area_value: "Dr. Babasaheb Ambedkar Colony part",
      ward_name: "21",
    },
    {
      area_name: "Prasun Dham",
      area_value: "Prasun Dham",
      ward_name: "23",
    },
    {
      area_name: "Ganga Ashiyana",
      area_value: "Ganga Ashiyana",
      ward_name: "23",
    },
    {
      area_name: "Kunal Residency",
      area_value: "Kunal Residency",
      ward_name: "23",
    },
    {
      area_name: "Swiss County",
      area_value: "Swiss County",
      ward_name: "23",
    },
    {
      area_name: "Thergaon Gavthan",
      area_value: "Thergaon Gavthan",
      ward_name: "23",
    },
    {
      area_name: "Padwalnagar part",
      area_value: "Padwalnagar part",
      ward_name: "23",
    },
    {
      area_name: "Ashoka Society",
      area_value: "Ashoka Society",
      ward_name: "23",
    },
    {
      area_name: "Sainath nagar",
      area_value: "Sainath nagar",
      ward_name: "23",
    },
    {
      area_name: "Samarth colony",
      area_value: "Samarth colony",
      ward_name: "23",
    },
    {
      area_name: "Prasundham",
      area_value: "Prasundham",
      ward_name: "24",
    },
    {
      area_name: "Ganga Ashiana",
      area_value: "Ganga Ashiana",
      ward_name: "24",
    },
    {
      area_name: "Kunal Residency",
      area_value: "Kunal Residency",
      ward_name: "24",
    },
    {
      area_name: "Swiss County",
      area_value: "Swiss County",
      ward_name: "24",
    },
    {
      area_name: "Thergaon Gaothan",
      area_value: "Thergaon Gaothan",
      ward_name: "24",
    },
    {
      area_name: "Padwal Nagar Part",
      area_value: "Padwal Nagar Part",
      ward_name: "24",
    },
    {
      area_name: "Asoka Society",
      area_value: "Asoka Society",
      ward_name: "24",
    },
    {
      area_name: "Sainath Nagar",
      area_value: "Sainath Nagar",
      ward_name: "24",
    },
    {
      area_name: "Samarth Colony",
      area_value: "Samarth Colony",
      ward_name: "24",
    },
    {
      area_name: "Tapkir Nagar",
      area_value: "Tapkir Nagar",
      ward_name: "27",
    },
    {
      area_name: "Shri Nagar",
      area_value: "Shri Nagar",
      ward_name: "27",
    },
    {
      area_name: "Shivthirth Nagar",
      area_value: "Shivthirth Nagar",
      ward_name: "27",
    },
    {
      area_name: "Baliram Garden",
      area_value: "Baliram Garden",
      ward_name: "27",
    },
    {
      area_name: "Rahatni Gaothan",
      area_value: "Rahatni Gaothan",
      ward_name: "27",
    },
    {
      area_name: "Tambe Shala Area",
      area_value: "Tambe Shala Area",
      ward_name: "27",
    },
    {
      area_name: "Sinhagad Colony",
      area_value: "Sinhagad Colony",
      ward_name: "27",
    },
    {
      area_name: "Raigad Colony",
      area_value: "Raigad Colony",
      ward_name: "27",
    },
    {
      area_name: "Laxmibai Tapakir School",
      area_value: "Laxmibai Tapakir School",
      ward_name: "27",
    },
    {
      area_name: "SNPP School",
      area_value: "SNPP School",
      ward_name: "27",
    },
    {
      area_name: "Royal Orange County",
      area_value: "Royal Orange County",
      ward_name: "27",
    },
    {
      area_name: "Galaxy Society",
      area_value: "Galaxy Society",
      ward_name: "27",
    },
    {
      area_name: "Chikhli Gaothan Bhag",
      area_value: "Chikhli Gaothan Bhag",
      ward_name: "1",
    },
    {
      area_name: "Patil Nagar",
      area_value: "Patil Nagar",
      ward_name: "1",
    },
    {
      area_name: "Ganesh Nagar",
      area_value: "Ganesh Nagar",
      ward_name: "1",
    },
    {
      area_name: "More Vasti Area",
      area_value: "More Vasti Area",
      ward_name: "1",
    },
    {
      area_name: "Sonwavne Vasti",
      area_value: "Sonwavne Vasti",
      ward_name: "1",
    },
    {
      area_name: "Nevale Vasti",
      area_value: "Nevale Vasti",
      ward_name: "11",
    },
    {
      area_name: "Hargude Vasti",
      area_value: "Hargude Vasti",
      ward_name: "11",
    },
    {
      area_name: "Kudalwadi Part",
      area_value: "Kudalwadi Part",
      ward_name: "11",
    },
    {
      area_name: "Krishna Nagar",
      area_value: "Krishna Nagar",
      ward_name: "11",
    },
    {
      area_name: "Sharad Nagar",
      area_value: "Sharad Nagar",
      ward_name: "11",
    },
    {
      area_name: "Koyana Nagar",
      area_value: "Koyana Nagar",
      ward_name: "11",
    },
    {
      area_name: "Mahatma Phule Nagar",
      area_value: "Mahatma Phule Nagar",
      ward_name: "11",
    },
    {
      area_name: "Purna Nagar",
      area_value: "Purna Nagar",
      ward_name: "11",
    },
    {
      area_name: "Gharkul Project",
      area_value: "Gharkul Project",
      ward_name: "11",
    },
    {
      area_name: "Ajantha Nagar",
      area_value: "Ajantha Nagar",
      ward_name: "11",
    },
    {
      area_name: "Durga Nagar",
      area_value: "Durga Nagar",
      ward_name: "11",
    },
    {
      area_name: "Talwade Gaothan",
      area_value: "Talwade Gaothan",
      ward_name: "12",
    },
    {
      area_name: "MIDC",
      area_value: "MIDC",
      ward_name: "12",
    },
    {
      area_name: "IT Park",
      area_value: "IT Park",
      ward_name: "12",
    },
    {
      area_name: "Jyotiba Temple",
      area_value: "Jyotiba Temple",
      ward_name: "12",
    },
    {
      area_name: "Sahyog Nagar",
      area_value: "Sahyog Nagar",
      ward_name: "12",
    },
    {
      area_name: "Rupi Nagar",
      area_value: "Rupi Nagar",
      ward_name: "12",
    },
    {
      area_name: "Triveni Nagar",
      area_value: "Triveni Nagar",
      ward_name: "12",
    },
    {
      area_name: "Mhetre Vasti Part",
      area_value: "Mhetre Vasti Part",
      ward_name: "12",
    },
    {
      area_name: "Tamhane Vasti Part",
      area_value: "Tamhane Vasti Part",
      ward_name: "12",
    },
    {
      area_name: "Nigdi Gaothan",
      area_value: "Nigdi Gaothan",
      ward_name: "13",
    },
    {
      area_name: "Sector 22- Ota-skim",
      area_value: "Sector 22- Ota-skim",
      ward_name: "13",
    },
    {
      area_name: "Yamuna Nagar",
      area_value: "Yamuna Nagar",
      ward_name: "13",
    },
    {
      area_name: "Mata Amritanandamayi Math Campus",
      area_value: "Mata Amritanandamayi Math Campus",
      ward_name: "13",
    },
    {
      area_name: "Srikrishna Temple Area",
      area_value: "Srikrishna Temple Area",
      ward_name: "13",
    },
    {
      area_name: "Sainath Nagar",
      area_value: "Sainath Nagar",
      ward_name: "13",
    },
    {
      area_name: "Moshi Gaothan",
      area_value: "Moshi Gaothan",
      ward_name: "3",
    },
    {
      area_name: "Gandharva Nagari",
      area_value: "Gandharva Nagari",
      ward_name: "3",
    },
    {
      area_name: "Sant Dnyaneshwar Nagar Part",
      area_value: "Sant Dnyaneshwar Nagar Part",
      ward_name: "3",
    },
    {
      area_name: "Sai Temple",
      area_value: "Sai Temple",
      ward_name: "3",
    },
    {
      area_name: "Gokhle mala",
      area_value: "Gokhle mala",
      ward_name: "3",
    },
    {
      area_name: "Alankapuram Society",
      area_value: "Alankapuram Society",
      ward_name: "3",
    },
    {
      area_name: "Vadmukhwadi",
      area_value: "Vadmukhwadi",
      ward_name: "3",
    },
    {
      area_name: "Kaljewadi",
      area_value: "Kaljewadi",
      ward_name: "3",
    },
    {
      area_name: "Tajnemala",
      area_value: "Tajnemala",
      ward_name: "3",
    },
    {
      area_name: "Chovisawadi",
      area_value: "Chovisawadi",
      ward_name: "3",
    },
    {
      area_name: "Charholi",
      area_value: "Charholi",
      ward_name: "3",
    },
    {
      area_name: "Dudulgaon",
      area_value: "Dudulgaon",
      ward_name: "3",
    },
    {
      area_name: "Part-1 Dighi",
      area_value: "Part-1 Dighi",
      ward_name: "4",
    },
    {
      area_name: "Gajanan Maharaj Nagar",
      area_value: "Gajanan Maharaj Nagar",
      ward_name: "4",
    },
    {
      area_name: "Bharatmata Nagar",
      area_value: "Bharatmata Nagar",
      ward_name: "4",
    },
    {
      area_name: "Gaikwad Nagar",
      area_value: "Gaikwad Nagar",
      ward_name: "4",
    },
    {
      area_name: "Bhandari Skyline",
      area_value: "Bhandari Skyline",
      ward_name: "4",
    },
    {
      area_name: "Samarth Nagar",
      area_value: "Samarth Nagar",
      ward_name: "4",
    },
    {
      area_name: "Krishna Nagar Part II V.S.N.L.",
      area_value: "Krishna Nagar Part II V.S.N.L.",
      ward_name: "4",
    },
    {
      area_name: "Ganesh Nagar",
      area_value: "Ganesh Nagar",
      ward_name: "4",
    },
    {
      area_name: "Ram Nagar",
      area_value: "Ram Nagar",
      ward_name: "4",
    },
    {
      area_name: "Bopkhel Gaothan",
      area_value: "Bopkhel Gaothan",
      ward_name: "4",
    },
    {
      area_name: "Ram Nagar",
      area_value: "Ram Nagar",
      ward_name: "5",
    },
    {
      area_name: "Sant Tukaram Nagar",
      area_value: "Sant Tukaram Nagar",
      ward_name: "5",
    },
    {
      area_name: "Gurudatta Colony",
      area_value: "Gurudatta Colony",
      ward_name: "5",
    },
    {
      area_name: "Gangotri Park",
      area_value: "Gangotri Park",
      ward_name: "5",
    },
    {
      area_name: "Sawant Nagar",
      area_value: "Sawant Nagar",
      ward_name: "5",
    },
    {
      area_name: "Mahadev Nagar",
      area_value: "Mahadev Nagar",
      ward_name: "5",
    },
    {
      area_name: "Gawli Nagar",
      area_value: "Gawli Nagar",
      ward_name: "5",
    },
    {
      area_name: "Shriram Colony",
      area_value: "Shriram Colony",
      ward_name: "5",
    },
    {
      area_name: "Sant Dnyaneshwar Nagar",
      area_value: "Sant Dnyaneshwar Nagar",
      ward_name: "5",
    },
    {
      area_name: "Chakrapani Vashahat Part",
      area_value: "Chakrapani Vashahat Part",
      ward_name: "5",
    },
    {
      area_name: "Shitalbagh",
      area_value: "Shitalbagh",
      ward_name: "7",
    },
    {
      area_name: "Century Enka Colony",
      area_value: "Century Enka Colony",
      ward_name: "7",
    },
    {
      area_name: "Suvidha Park",
      area_value: "Suvidha Park",
      ward_name: "7",
    },
    {
      area_name: "Apte Colony",
      area_value: "Apte Colony",
      ward_name: "7",
    },
    {
      area_name: "Sandvik Colony",
      area_value: "Sandvik Colony",
      ward_name: "7",
    },
    {
      area_name: "Khandoba Mal",
      area_value: "Khandoba Mal",
      ward_name: "7",
    },
    {
      area_name: "Gawhane Vasti",
      area_value: "Gawhane Vasti",
      ward_name: "7",
    },
    {
      area_name: "Bhosari Gaothan",
      area_value: "Bhosari Gaothan",
      ward_name: "7",
    },
    {
      area_name: "Landewadi",
      area_value: "Landewadi",
      ward_name: "7",
    },
    {
      area_name: "Shanti Nagar",
      area_value: "Shanti Nagar",
      ward_name: "7",
    },
    {
      area_name: "Malwadi",
      area_value: "Malwadi",
      ward_name: "25",
    },
    {
      area_name: "Punawale",
      area_value: "Punawale",
      ward_name: "25",
    },
    {
      area_name: "Pandhare Vasti",
      area_value: "Pandhare Vasti",
      ward_name: "25",
    },
    {
      area_name: "Kate Vasti",
      area_value: "Kate Vasti",
      ward_name: "25",
    },
    {
      area_name: "Navale Vasti",
      area_value: "Navale Vasti",
      ward_name: "25",
    },
    {
      area_name: "Tathawade",
      area_value: "Tathawade",
      ward_name: "25",
    },
    {
      area_name: "Ashoka Nagar",
      area_value: "Ashoka Nagar",
      ward_name: "25",
    },
    {
      area_name: "Nimbalkar Nagar",
      area_value: "Nimbalkar Nagar",
      ward_name: "25",
    },
    {
      area_name: "Bhumakar Vasti",
      area_value: "Bhumakar Vasti",
      ward_name: "25",
    },
    {
      area_name: "Wakad Kala-Khadak",
      area_value: "Wakad Kala-Khadak",
      ward_name: "25",
    },
    {
      area_name: "Munjoba Nagar",
      area_value: "Munjoba Nagar",
      ward_name: "25",
    },
    {
      area_name: "Marane Vasti",
      area_value: "Marane Vasti",
      ward_name: "25",
    },
    {
      area_name: "Bhujbal Vasti",
      area_value: "Bhujbal Vasti",
      ward_name: "25",
    },
    {
      area_name: "Wakadkar Vasti",
      area_value: "Wakadkar Vasti",
      ward_name: "25",
    },
    {
      area_name: "Kemse Vasti",
      area_value: "Kemse Vasti",
      ward_name: "25",
    },
    {
      area_name: "Rohan Tarang Society",
      area_value: "Rohan Tarang Society",
      ward_name: "25",
    },
    {
      area_name: "Pristine Society",
      area_value: "Pristine Society",
      ward_name: "25",
    },
    {
      area_name: "Swara Pride Residency",
      area_value: "Swara Pride Residency",
      ward_name: "25",
    },
    {
      area_name: "Pimpale Nilakh",
      area_value: "Pimpale Nilakh",
      ward_name: "26",
    },
    {
      area_name: "Vishal Nagar",
      area_value: "Vishal Nagar",
      ward_name: "26",
    },
    {
      area_name: "Parkstreet",
      area_value: "Parkstreet",
      ward_name: "26",
    },
    {
      area_name: "Kaspate Vasti",
      area_value: "Kaspate Vasti",
      ward_name: "26",
    },
    {
      area_name: "Anmol Residency",
      area_value: "Anmol Residency",
      ward_name: "26",
    },
    {
      area_name: "Dhanraj Park",
      area_value: "Dhanraj Park",
      ward_name: "26",
    },
    {
      area_name: "Dattamandir",
      area_value: "Dattamandir",
      ward_name: "26",
    },
    {
      area_name: "Annabhau Sathe Nagar",
      area_value: "Annabhau Sathe Nagar",
      ward_name: "26",
    },
    {
      area_name: "Venu Nagar Part",
      area_value: "Venu Nagar Part",
      ward_name: "26",
    },
    {
      area_name: "Rakshak Society",
      area_value: "Rakshak Society",
      ward_name: "26",
    },
    {
      area_name: "Bharat Electronics",
      area_value: "Bharat Electronics",
      ward_name: "26",
    },
    {
      area_name: "Five garden",
      area_value: "Five garden",
      ward_name: "28",
    },
    {
      area_name: "Shivar Garden",
      area_value: "Shivar Garden",
      ward_name: "28",
    },
    {
      area_name: "Planet Millennium",
      area_value: "Planet Millennium",
      ward_name: "28",
    },
    {
      area_name: "Kapse Lawns",
      area_value: "Kapse Lawns",
      ward_name: "28",
    },
    {
      area_name: "Ram Nagar",
      area_value: "Ram Nagar",
      ward_name: "28",
    },
    {
      area_name: "Pimple Saudagar",
      area_value: "Pimple Saudagar",
      ward_name: "28",
    },
    {
      area_name: "Kunal Icon",
      area_value: "Kunal Icon",
      ward_name: "28",
    },
    {
      area_name: "Rose Land",
      area_value: "Rose Land",
      ward_name: "28",
    },
    {
      area_name: "Govind Garden",
      area_value: "Govind Garden",
      ward_name: "28",
    },
    {
      area_name: "Kalpataru Estate",
      area_value: "Kalpataru Estate",
      ward_name: "29",
    },
    {
      area_name: "Kranti Nagar",
      area_value: "Kranti Nagar",
      ward_name: "29",
    },
    {
      area_name: "Kashid Park",
      area_value: "Kashid Park",
      ward_name: "29",
    },
    {
      area_name: "Gagangiri Park",
      area_value: "Gagangiri Park",
      ward_name: "29",
    },
    {
      area_name: "Jawalkar Nagar",
      area_value: "Jawalkar Nagar",
      ward_name: "29",
    },
    {
      area_name: "Shivneri Colony",
      area_value: "Shivneri Colony",
      ward_name: "29",
    },
    {
      area_name: "Gulmohar Colony",
      area_value: "Gulmohar Colony",
      ward_name: "29",
    },
    {
      area_name: "Onkar Colony",
      area_value: "Onkar Colony",
      ward_name: "29",
    },
    {
      area_name: "Gokul Nagari",
      area_value: "Gokul Nagari",
      ward_name: "29",
    },
    {
      area_name: "Bhalekar Nagar",
      area_value: "Bhalekar Nagar",
      ward_name: "29",
    },
    {
      area_name: "Pimple Gurav",
      area_value: "Pimple Gurav",
      ward_name: "29",
    },
    {
      area_name: "Sudarshan Nagar",
      area_value: "Sudarshan Nagar",
      ward_name: "29",
    },
    {
      area_name: "Vaidu Vasti",
      area_value: "Vaidu Vasti",
      ward_name: "29",
    },
    {
      area_name: "Chikhali gaothan part",
      area_value: "Chikhali gaothan part",
      ward_name: "2",
    },
    {
      area_name: "River residency",
      area_value: "River residency",
      ward_name: "2",
    },
    {
      area_name: "Crystal city",
      area_value: "Crystal city",
      ward_name: "2",
    },
    {
      area_name: "Swaraj residency",
      area_value: "Swaraj residency",
      ward_name: "2",
    },
    {
      area_name: "Gandharva excellence",
      area_value: "Gandharva excellence",
      ward_name: "2",
    },
    {
      area_name: "Bankar Vasti",
      area_value: "Bankar Vasti",
      ward_name: "2",
    },
    {
      area_name: "Borhadewadi",
      area_value: "Borhadewadi",
      ward_name: "2",
    },
    {
      area_name: "Woods villa",
      area_value: "Woods villa",
      ward_name: "2",
    },
    {
      area_name: "Jadhavwadi",
      area_value: "Jadhavwadi",
      ward_name: "2",
    },
    {
      area_name: "Raje Shivaji Nagar",
      area_value: "Raje Shivaji Nagar",
      ward_name: "2",
    },
    {
      area_name: "Kudalwadi part",
      area_value: "Kudalwadi part",
      ward_name: "2",
    },
    {
      area_name: "Dhavde Vasti",
      area_value: "Dhavde Vasti",
      ward_name: "6",
    },
    {
      area_name: "Bhagat Vasti",
      area_value: "Bhagat Vasti",
      ward_name: "6",
    },
    {
      area_name: "Gulve Vasti",
      area_value: "Gulve Vasti",
      ward_name: "6",
    },
    {
      area_name: "Chakrapani vasahat part",
      area_value: "Chakrapani vasahat part",
      ward_name: "6",
    },
    {
      area_name: "Pandav Nagar",
      area_value: "Pandav Nagar",
      ward_name: "6",
    },
    {
      area_name: "Roshal garden premises",
      area_value: "Roshal garden premises",
      ward_name: "6",
    },
    {
      area_name: "Sadguru Nagar",
      area_value: "Sadguru Nagar",
      ward_name: "6",
    },
    {
      area_name: "Jai Ganesh Empire",
      area_value: "Jai Ganesh Empire",
      ward_name: "8",
    },
    {
      area_name: "Jalvayu Vihar",
      area_value: "Jalvayu Vihar",
      ward_name: "8",
    },
    {
      area_name: "Central Vihar",
      area_value: "Central Vihar",
      ward_name: "8",
    },
    {
      area_name: "Maharashtra Colony",
      area_value: "Maharashtra Colony",
      ward_name: "8",
    },
    {
      area_name: "Indrayani Nagar",
      area_value: "Indrayani Nagar",
      ward_name: "8",
    },
    {
      area_name: "Khande Vasti",
      area_value: "Khande Vasti",
      ward_name: "8",
    },
    {
      area_name: "Gavali Matha",
      area_value: "Gavali Matha",
      ward_name: "8",
    },
    {
      area_name: "Balajinguri",
      area_value: "Balajinguri",
      ward_name: "8",
    },
    {
      area_name: "Tata Motors",
      area_value: "Tata Motors",
      ward_name: "9",
    },
    {
      area_name: "Yashwant Nagar",
      area_value: "Yashwant Nagar",
      ward_name: "9",
    },
    {
      area_name: "Vitthal Nagar",
      area_value: "Vitthal Nagar",
      ward_name: "9",
    },
    {
      area_name: "Udyam Nagar",
      area_value: "Udyam Nagar",
      ward_name: "9",
    },
    {
      area_name: "Swapna Nagari",
      area_value: "Swapna Nagari",
      ward_name: "9",
    },
    {
      area_name: "Antariksha Society",
      area_value: "Antariksha Society",
      ward_name: "9",
    },
    {
      area_name: "Ajmera Society",
      area_value: "Ajmera Society",
      ward_name: "9",
    },
    {
      area_name: "Vastu Udyog",
      area_value: "Vastu Udyog",
      ward_name: "9",
    },
    {
      area_name: "Masulkar Colony",
      area_value: "Masulkar Colony",
      ward_name: "9",
    },
    {
      area_name: "Mahindra Royal",
      area_value: "Mahindra Royal",
      ward_name: "9",
    },
    {
      area_name: "Kharalwadi",
      area_value: "Kharalwadi",
      ward_name: "9",
    },
    {
      area_name: "Gandhi Nagar",
      area_value: "Gandhi Nagar",
      ward_name: "9",
    },
    {
      area_name: "Nehru Nagar",
      area_value: "Nehru Nagar",
      ward_name: "9",
    },
    {
      area_name: "Walhekarwadi part",
      area_value: "Walhekarwadi part",
      ward_name: "16",
    },
    {
      area_name: "Gurudwara",
      area_value: "Gurudwara",
      ward_name: "16",
    },
    {
      area_name: "Nano Home Society",
      area_value: "Nano Home Society",
      ward_name: "16",
    },
    {
      area_name: "Shinde Vasti",
      area_value: "Shinde Vasti",
      ward_name: "16",
    },
    {
      area_name: "Royal Casa Society",
      area_value: "Royal Casa Society",
      ward_name: "16",
    },
    {
      area_name: "Sector No. 29",
      area_value: "Sector No. 29",
      ward_name: "16",
    },
    {
      area_name: "Ravet",
      area_value: "Ravet",
      ward_name: "16",
    },
    {
      area_name: "Nandgiri Society",
      area_value: "Nandgiri Society",
      ward_name: "16",
    },
    {
      area_name: "Vikas Nagar",
      area_value: "Vikas Nagar",
      ward_name: "16",
    },
    {
      area_name: "Crystal City",
      area_value: "Crystal City",
      ward_name: "16",
    },
    {
      area_name: "Mammurdi",
      area_value: "Mammurdi",
      ward_name: "16",
    },
    {
      area_name: "Kivale",
      area_value: "Kivale",
      ward_name: "16",
    },
    {
      area_name: "Dalwi Nagar",
      area_value: "Dalwi Nagar",
      ward_name: "17",
    },
    {
      area_name: "Premlok Park",
      area_value: "Premlok Park",
      ward_name: "17",
    },
    {
      area_name: "Bhoir Nagar",
      area_value: "Bhoir Nagar",
      ward_name: "17",
    },
    {
      area_name: "Giriraj Society",
      area_value: "Giriraj Society",
      ward_name: "17",
    },
    {
      area_name: "Rail Vihar Society",
      area_value: "Rail Vihar Society",
      ward_name: "17",
    },
    {
      area_name: "Shiva Nagari",
      area_value: "Shiva Nagari",
      ward_name: "17",
    },
    {
      area_name: "Nagsen Nagar",
      area_value: "Nagsen Nagar",
      ward_name: "17",
    },
    {
      area_name: "Aher Nagar",
      area_value: "Aher Nagar",
      ward_name: "17",
    },
    {
      area_name: "Valhekarwadi Gaothan",
      area_value: "Valhekarwadi Gaothan",
      ward_name: "17",
    },
    {
      area_name: "Chinchwade Nagar",
      area_value: "Chinchwade Nagar",
      ward_name: "17",
    },
    {
      area_name: "Balwant Nagar",
      area_value: "Balwant Nagar",
      ward_name: "17",
    },
    {
      area_name: "Bijli Nagar",
      area_value: "Bijli Nagar",
      ward_name: "17",
    },
    {
      area_name: "S.K.F. Colony",
      area_value: "S.K.F. Colony",
      ward_name: "18",
    },
    {
      area_name: "Ruston colony",
      area_value: "Ruston colony",
      ward_name: "18",
    },
    {
      area_name: "Pawana Nagar",
      area_value: "Pawana Nagar",
      ward_name: "18",
    },
    {
      area_name: "Vetal Nagar",
      area_value: "Vetal Nagar",
      ward_name: "18",
    },
    {
      area_name: "Chinchwad Gaothan",
      area_value: "Chinchwad Gaothan",
      ward_name: "18",
    },
    {
      area_name: "Keshav Nagar",
      area_value: "Keshav Nagar",
      ward_name: "18",
    },
    {
      area_name: "Tanaji Nagar",
      area_value: "Tanaji Nagar",
      ward_name: "18",
    },
    {
      area_name: "Kakade Park",
      area_value: "Kakade Park",
      ward_name: "18",
    },
    {
      area_name: "Moraya Raj Park",
      area_value: "Moraya Raj Park",
      ward_name: "18",
    },
    {
      area_name: "Darshan Hall",
      area_value: "Darshan Hall",
      ward_name: "18",
    },
    {
      area_name: "Manik Colony",
      area_value: "Manik Colony",
      ward_name: "18",
    },
    {
      area_name: "Lakshmi Nagar",
      area_value: "Lakshmi Nagar",
      ward_name: "18",
    },
    {
      area_name: "Yashopuram Society",
      area_value: "Yashopuram Society",
      ward_name: "18",
    },
    {
      area_name: "Kalewadi",
      area_value: "Kalewadi",
      ward_name: "22",
    },
    {
      area_name: "Vijay Nagar",
      area_value: "Vijay Nagar",
      ward_name: "22",
    },
    {
      area_name: "Nirmal Nagar",
      area_value: "Nirmal Nagar",
      ward_name: "22",
    },
    {
      area_name: "Adarsh Nagar",
      area_value: "Adarsh Nagar",
      ward_name: "22",
    },
    {
      area_name: "Pawana Nagar",
      area_value: "Pawana Nagar",
      ward_name: "22",
    },
    {
      area_name: "Jyotiba Nagar part",
      area_value: "Jyotiba Nagar part",
      ward_name: "22",
    },
    {
      area_name: "Nadhe Nagar",
      area_value: "Nadhe Nagar",
      ward_name: "22",
    },
    {
      area_name: "Sant Dnyaneshwar Nagar (MHADA)",
      area_value: "Sant Dnyaneshwar Nagar (MHADA)",
      ward_name: "10",
    },
    {
      area_name: "Morwadi",
      area_value: "Morwadi",
      ward_name: "10",
    },
    {
      area_name: "Lalitoti Nagar",
      area_value: "Lalitoti Nagar",
      ward_name: "10",
    },
    {
      area_name: "Amruteshwar Colony",
      area_value: "Amruteshwar Colony",
      ward_name: "10",
    },
    {
      area_name: "Indira Nagar",
      area_value: "Indira Nagar",
      ward_name: "10",
    },
    {
      area_name: "Saraswati University School Campus",
      area_value: "Saraswati University School Campus",
      ward_name: "10",
    },
    {
      area_name: "Ambedkar Nagar",
      area_value: "Ambedkar Nagar",
      ward_name: "10",
    },
    {
      area_name: "HDFC Colony",
      area_value: "HDFC Colony",
      ward_name: "10",
    },
    {
      area_name: "Datt Nagar",
      area_value: "Datt Nagar",
      ward_name: "10",
    },
    {
      area_name: "Vidya Nagar",
      area_value: "Vidya Nagar",
      ward_name: "10",
    },
    {
      area_name: "Shahu Nagar",
      area_value: "Shahu Nagar",
      ward_name: "10",
    },
    {
      area_name: "Vrindavan Society",
      area_value: "Vrindavan Society",
      ward_name: "10",
    },
    {
      area_name: "Sambhaji Nagar",
      area_value: "Sambhaji Nagar",
      ward_name: "10",
    },
    {
      area_name: "Chinchwad Station",
      area_value: "Chinchwad Station",
      ward_name: "14",
    },
    {
      area_name: "Mahavir Park",
      area_value: "Mahavir Park",
      ward_name: "14",
    },
    {
      area_name: "Mohan Nagar",
      area_value: "Mohan Nagar",
      ward_name: "14",
    },
    {
      area_name: "Ram Nagar",
      area_value: "Ram Nagar",
      ward_name: "14",
    },
    {
      area_name: "Kalbhor Nagar",
      area_value: "Kalbhor Nagar",
      ward_name: "14",
    },
    {
      area_name: "Aishwaryam Society",
      area_value: "Aishwaryam Society",
      ward_name: "14",
    },
    {
      area_name: "Shubhashri Society",
      area_value: "Shubhashri Society",
      ward_name: "14",
    },
    {
      area_name: "Jai Ganesh Vision",
      area_value: "Jai Ganesh Vision",
      ward_name: "14",
    },
    {
      area_name: "Vivek Nagar",
      area_value: "Vivek Nagar",
      ward_name: "14",
    },
    {
      area_name: "Vitthalwadi",
      area_value: "Vitthalwadi",
      ward_name: "14",
    },
    {
      area_name: "Bajaj Auto",
      area_value: "Bajaj Auto",
      ward_name: "14",
    },
    {
      area_name: "Dattawadi",
      area_value: "Dattawadi",
      ward_name: "14",
    },
    {
      area_name: "Tuljai Vasti",
      area_value: "Tuljai Vasti",
      ward_name: "14",
    },
    {
      area_name: "Aakurdi Gaothan",
      area_value: "Aakurdi Gaothan",
      ward_name: "15",
    },
    {
      area_name: "Ganga Nagar",
      area_value: "Ganga Nagar",
      ward_name: "15",
    },
    {
      area_name: "Vahtuk Nagari",
      area_value: "Vahtuk Nagari",
      ward_name: "15",
    },
    {
      area_name: "Sector No. 24, 25, 26, 27, 27 A, 28",
      area_value: "Sector No. 24, 25, 26, 27, 27 A, 28",
      ward_name: "15",
    },
    {
      area_name: "Sindhu Nagar",
      area_value: "Sindhu Nagar",
      ward_name: "15",
    },
    {
      area_name: "Parmar Park",
      area_value: "Parmar Park",
      ward_name: "15",
    },
    {
      area_name: "Swapnapurti Society",
      area_value: "Swapnapurti Society",
      ward_name: "15",
    },
    {
      area_name: "Central Colony",
      area_value: "Central Colony",
      ward_name: "15",
    },
    {
      area_name: "LIC",
      area_value: "LIC",
      ward_name: "15",
    },
    {
      area_name: "Excise",
      area_value: "Excise",
      ward_name: "15",
    },
    {
      area_name: "Vijay Nagar",
      area_value: "Vijay Nagar",
      ward_name: "19",
    },
    {
      area_name: "New SKF Colony",
      area_value: "New SKF Colony",
      ward_name: "19",
    },
    {
      area_name: "Udyog Nagar",
      area_value: "Udyog Nagar",
      ward_name: "19",
    },
    {
      area_name: "Queen's Town",
      area_value: "Queen's Town",
      ward_name: "19",
    },
    {
      area_name: "Sudarsha Nagar",
      area_value: "Sudarsha Nagar",
      ward_name: "19",
    },
    {
      area_name: "Sridhara Lawns",
      area_value: "Sridhara Lawns",
      ward_name: "19",
    },
    {
      area_name: "Anand Nagar",
      area_value: "Anand Nagar",
      ward_name: "19",
    },
    {
      area_name: "Bhoir Colony",
      area_value: "Bhoir Colony",
      ward_name: "19",
    },
    {
      area_name: "Gawade Park",
      area_value: "Gawade Park",
      ward_name: "19",
    },
    {
      area_name: "Empire Estate",
      area_value: "Empire Estate",
      ward_name: "19",
    },
    {
      area_name: "Wisdom Park",
      area_value: "Wisdom Park",
      ward_name: "19",
    },
    {
      area_name: "Dr. Babasaheb Ambedkar Colony Part",
      area_value: "Dr. Babasaheb Ambedkar Colony Part",
      ward_name: "19",
    },
    {
      area_name: "Bhim Nagar",
      area_value: "Bhim Nagar",
      ward_name: "19",
    },
    {
      area_name: "Niradhar Nagar",
      area_value: "Niradhar Nagar",
      ward_name: "19",
    },
    {
      area_name: "Emperor Ashok Nagar",
      area_value: "Emperor Ashok Nagar",
      ward_name: "19",
    },
    {
      area_name: "Mata Ramabai Ambedkar Nagar",
      area_value: "Mata Ramabai Ambedkar Nagar",
      ward_name: "19",
    },
    {
      area_name: "Buddha Nagar",
      area_value: "Buddha Nagar",
      ward_name: "19",
    },
    {
      area_name: "Valmiki Nagar",
      area_value: "Valmiki Nagar",
      ward_name: "19",
    },
    {
      area_name: "Sanitary Chal",
      area_value: "Sanitary Chal",
      ward_name: "19",
    },
    {
      area_name: "Bhat Nagar",
      area_value: "Bhat Nagar",
      ward_name: "19",
    },
    {
      area_name: "Bhaji Mandai",
      area_value: "Bhaji Mandai",
      ward_name: "19",
    },
    {
      area_name: "Pimpri Camp",
      area_value: "Pimpri Camp",
      ward_name: "19",
    },
  ];

  const ageOptions = [
    { label: "Under 18/ १८ पेक्षा कमी", value: "Under 18" },
    { label: "18-24/ १८-२४", value: "18-24" },
    { label: "25-34/ २५-३४", value: "25-34" },
    { label: "35-44/ ३५-४४", value: "35-44" },
    { label: "45-54/ ४५-५४", value: "45-54" },
    { label: "55-64/ ५५-६४", value: "55-64" },
    { label: "65+/ ६५ पेक्षा जास्त", value: "65+" },
  ];

  const verticalArray = [
    { label: "Roads/ रस्ते", value: "Roads/ रस्ते" },
    {
      label: "Waste Management/ कचरा व्यवस्थापन",
      value: "Waste Management/ कचरा व्यवस्थापन",
    },
    { label: "Water Supply/ पाणीपुरवठा", value: "Water Supply/ पाणीपुरवठा" },
    {
      label: "Storm Water Drainage/ पावसाळी पाण्याचा निचरा",
      value: "Storm Water Drainage/ पावसाळी पाण्याचा निचरा",
    },
    {
      label: "Sewage management / मैला व्यवस्थापन",
      value: "Sewage management / मैला व्यवस्थापन",
    },
    {
      label: "Streetlights/ रस्त्यावरील दिवे",
      value: "Streetlights/ रस्त्यावरील दिवे",
    },
    { label: "Footpaths/ पदपथ", value: "Footpaths/ पदपथ" },
    {
      label: "Public Toilets/ सार्वजनिक शौचालये",
      value: "Public Toilets/ सार्वजनिक शौचालये",
    },
    {
      label: "Parks and Playgrounds/ उद्याने आणि खेळाची मैदाने",
      value: "Parks and Playgrounds/ उद्याने आणि खेळाची मैदाने",
    },
    {
      label: "EV charging / ईव्ही चार्जिंग",
      value: "EV charging / ईव्ही चार्जिंग",
    },
    {
      label: "Bus stops/BRTS / बस स्टॉप्स/बीआरटीएस",
      value: "Bus stops/BRTS / बस स्टॉप्स/बीआरटीएस",
    },
    { label: "Health/ आरोग्य", value: "Health/ आरोग्य" },
    { label: "Sports/ क्रीडा", value: "Sports/ क्रीडा" },
    {
      label: "Parking Facility/ पार्किंग सुविधा",
      value: "Parking Facility/ पार्किंग सुविधा",
    },
    {
      label: "Cycle tracks/ सायकल ट्रॅक्स",
      value: "Cycle tracks/ सायकल ट्रॅक्स",
    },
    {
      label: "Traffic Lights / ट्रॅफिक लाईट",
      value: "Traffic Lights / ट्रॅफिक लाईट",
    },
    {
      label: "Sign and Signages / आकाशचिन्ह",
      value: "Sign and Signages / आकाशचिन्ह",
    },
    {
      label: "Water logging / पाणी साचणे",
      value: "Water logging / पाणी साचणे",
    },
    {
      label:
        "Public Amenities / सार्वजनिक सुविधा (रस्त्याशी संबंधित फर्निचर/जलतरण तलाव/वाचनालय/ नाट्यगृह)",
      value:
        "Public Amenities / सार्वजनिक सुविधा (रस्त्याशी संबंधित फर्निचर/जलतरण तलाव/वाचनालय/ नाट्यगृह)",
    },
    { label: "Education / शिक्षण", value: "Education / शिक्षण" },
    {
      label: "Other/ इतर",
      value: "Other/ इतर",
    },
  ];
  useEffect(() => {
    if (reduxFormSubmit) {
      navigate("/survey-submit");
    }
    if (formData) {
      populateReduxData();
    }
  }, []);

  useEffect(() => {
    if (mobile.length === 10) {
      checkFormValidity();
    }
  }, [mobile]);

  const checkFormValidity = async () => {
    setLimitLoader(true);

    let payload = {
      citizenMobileNo: mobile,
    };

    await fetch(
      process.env.REACT_APP_BASE_URL + "/user/v1/checkingNoOfFillForm",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.code === 200) {
          if (response.result.isMoreThan3form) {
            navigate("/max-submit");
            setLimitLoader(false);
          }
          setLimitLoader(false);
        }
      })
      .catch((Err) => {
        console.log("Err while getting dashcards", Err);
        setLimitLoader(false);
      });
  };

  const populateReduxData = () => {
    if (formData.applicantName) {
      setName(formData.applicantName);
    }

    if (formData.applicantMobile) {
      setmobile(formData.applicantMobile);
    }

    if (formData.applicantgender) {
      setgender(formData.applicantgender);
    }

    if (formData.applicantAge) {
      setage(formData.applicantAge);
    }

    if (formData.suggestedzone) {
      setzone(formData.suggestedzone);
    }

    if (formData.suggestedward) {
      setward(formData.suggestedward);
      setWardName(formData.suggestedward[0].wardno);
    }

    if (formData.suggestedarea) {
      setarea(formData.suggestedarea);
    }

    if (formData.selectedVertical) {
      setSelectedVertical(formData.selectedVertical);
    }
  };

  const isValidate = () => {
    let errors = {};

    if (!name) {
      errors.name = "Please Enter Name";
    }

    if (!mobile) {
      errors.mobile = "Please Enter Mobile Number";
    } else if (mobile.length !== 10) {
      errors.mobile = "Please Enter Valid Mobile Number";
    }

    if (area.length === 0) {
      errors.area = "Please Select Area of Suggested Work";
    }

    if (!selectedVertical) {
      errors.selectedVertical = "Please Select Vertical";
    }

    if (Object.keys(errors).length > 0) {
      alert("Please Add Needed Information");
    }

    setSurveyError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleNext = () => {
    if (isValidate()) {
      dispatch(
        setFormData({
          applicantName: name,
          applicantMobile: mobile,
          applicantAge: age,
          applicantgender: gender,
          suggestedzone: zone,
          suggestedward: ward,
          suggestedarea: area,
          selectedVertical: selectedVertical,
        })
      );
      navigate("/vertical-form");
    }
  };

  const getWardZoneInfo = (selectedArea) => {
    let selectedWard = wardDrop.filter(
      (row) => row.wardno === selectedArea.ward_name
    );
    setward(selectedWard);
    setWardName(selectedWard[0].wardno);

    let selectedZone = zonesDrop.filter(
      (row) => row.value === selectedWard[0].zone
    );
    setzone(selectedZone[0].value);
  };

  return (
    <Container className="d-flex justify-content-center align-items-center">
      <div className="form-container">
        {/* Personal Information */}
        <Row className="d-flex flex-column mt-5">
          <h4 className="section-heading">
            Personal Information/ व्यक्तिगत माहिती
          </h4>
          <Col md={6} className="d-flex align-items-center mt-2">
            <Form.Label className="form-label">
              Name/ नाव <span style={{ color: "red" }}> *</span>{" "}
            </Form.Label>
            <Form.Control
              value={name}
              className="me-3"
              onChange={(e) => {
                if (e.target.value.match(/^[a-zA-Z ]+$/)) {
                  setName(e.target.value);
                } else if (e.target.value.length === 0) {
                  setName(e.target.value);
                }
              }}
            />
          </Col>
          {surveyError.name ? (
            <span className="errormsg">{surveyError.name}</span>
          ) : null}
          <Col md={6} className="d-flex align-items-center mt-2">
            <Form.Label className="form-label">
              Phone Number/ संपर्क क्रमांक{" "}
              <span style={{ color: "red" }}> *</span>
            </Form.Label>
            <Form.Control
              value={mobile}
              className="me-3"
              onChange={(e) => {
                if (
                  e.target.value.match(/^[0-9]+$/) &&
                  e.target.value.length <= 10
                ) {
                  setmobile(e.target.value);
                } else if (e.target.value.length === 0) {
                  setmobile(e.target.value);
                }
              }}
            />
          </Col>
          {surveyError.mobile ? (
            <span className="errormsg">{surveyError.mobile}</span>
          ) : null}
        </Row>

        {/* Gender */}
        <Row className="d-flex flex-column mt-5">
          <h4 className="section-heading">Gender/ लिंग</h4>
          <div style={{ marginLeft: 20 }}>
            <Form.Check
              type="radio"
              label="Male/ पुरुष"
              value="male"
              name="gender"
              className="gender"
              id="male"
              checked={gender === "male"}
              onChange={(e) => {
                setgender(e.target.value);
              }}
            />
            <Form.Check
              type="radio"
              label="Female/ स्त्री"
              name="gender"
              value="female"
              className="gender"
              id="female"
              checked={gender === "female"}
              onChange={(e) => {
                setgender(e.target.value);
              }}
            />
            <Form.Check
              type="radio"
              label="Other/ इतर"
              name="gender"
              value="other"
              className="gender"
              id="other"
              checked={gender === "other"}
              onChange={(e) => {
                setgender(e.target.value);
              }}
            />
          </div>
        </Row>

        {/*Age */}
        <Row className="d-flex flex-column mt-5">
          <h4 className="section-heading">Age/वय</h4>
          <div style={{ width: "100%" }} className="d-flex flex-wrap">
            {ageOptions.map((item, index) => {
              return (
                <div className="age-div" key={index}>
                  <Form.Check
                    type="radio"
                    label={item.label}
                    name="age"
                    value="Under 18"
                    className="gender"
                    id={item.label}
                    checked={age === item.value}
                    onChange={(e) => {
                      setage(item.value);
                    }}
                  />
                </div>
              );
            })}
          </div>
          {/* <div style={{ width: "100%" }} className="d-flex flex-wrap">
            <div className="age-div">
              <Form.Check
                type="radio"
                label="Under 18/ १८ पेक्षा कमी"
                name="age"
                value="Under 18"
                className="gender"
                id="Under 18/ १८ पेक्षा कमी"
                checked={age === "Under 18"}
                onChange={(e) => {
                  setage(e.target.value);
                }}
              />
            </div>
            <div className="age-div">
              <Form.Check
                type="radio"
                label="45-54/ ४५-५४"
                name="age"
                value="45-54"
                className="gender"
                id="45-54/ ४५-५४"
                checked={age === "45-54"}
                onChange={(e) => {
                  setage(e.target.value);
                }}
              />
            </div>
            <div className="age-div">
              <Form.Check
                type="radio"
                label="18-24/ १८-२४"
                name="age"
                value="18-24"
                className="gender"
                id="18-24/ १८-२४"
                checked={age === "18-24"}
                onChange={(e) => {
                  setage(e.target.value);
                }}
              />
            </div>
            <div className="age-div">
              <Form.Check
                type="radio"
                label="55-64/ ५५-६४"
                name="age"
                value="55-64"
                className="gender"
                id="55-64/ ५५-६४"
                checked={age === "55-64"}
                onChange={(e) => {
                  setage(e.target.value);
                }}
              />
            </div>
            <div className="age-div">
              <Form.Check
                type="radio"
                label="25-34/ २५-३४"
                name="age"
                value="25-34"
                className="gender"
                id="25-34/ २५-३४"
                checked={age === "25-34"}
                onChange={(e) => {
                  setage(e.target.value);
                }}
              />
            </div>
            <div className="age-div">
              <Form.Check
                type="radio"
                label="65+/ ६५ पेक्षा जास्त"
                name="age"
                value="65+"
                className="gender"
                id="65+/ ६५ पेक्षा जास्त"
                checked={age === "65+"}
                onChange={(e) => {
                  setage(e.target.value);
                }}
              />
            </div>
            <div className="age-div">
              <Form.Check
                type="radio"
                label="35-44/ ३५-४४"
                name="age"
                value="35-44"
                className="gender"
                id="35-44/ ३५-४४"
                checked={age === "35-44"}
                onChange={(e) => {
                  setage(e.target.value);
                }}
              />
            </div>
          </div> */}
        </Row>

        {/* Area Dropdown */}
        <Row className="d-flex flex-column mt-5">
          <h4 className="section-heading">
            <span style={{ color: "red" }}> *</span> Name of Area where you want
            to suggest work
          </h4>
          <h5 className="section-heading">काम सुचवायचे आहे त्या एरियाचे नाव</h5>
          <h6>
            (झोन माहिती नसल्यास सारथी हेल्पलाइनवर खात्री करावी - ८८८८००६६६६)
          </h6>
          {surveyError.area ? (
            <span className="errormsg">{surveyError.area}</span>
          ) : null}
          <Row>
            <Col md={4} className="mt-2">
              <Form.Label>* Area/एरिया</Form.Label>
              <Typeahead
                id="basic-typeahead-single"
                labelKey="area_name"
                selected={area}
                onChange={(item) => {
                  if (item.length > 0) {
                    setarea(item);
                    getWardZoneInfo(item[0]);
                  } else {
                    setarea([]);
                  }
                }}
                options={areasDrop}
                placeholder="Choose a area..."
              />
            </Col>
          </Row>
        </Row>

        {/* Zone */}
        <Row className="d-flex flex-column mt-5">
          <h4 className="section-heading">
            * Name of Zone office where you want to suggest work
          </h4>
          <h5 className="section-heading">
            काम सुचवायचे आहे त्या क्षेत्रीय कार्यालयाचे नाव
          </h5>
          <h6>
            (झोन माहिती नसल्यास सारथी हेल्पलाइनवर खात्री करावी - ८८८८००६६६६
            किंवा या लिंकवर क्लिक करा
            <a href="https://www.pcmcindia.gov.in/marathi/ward_info_m.php">
              {" "}
              https://www.pcmcindia.gov.in/marathi/ward_info_m.php
            </a>{" "}
            )
          </h6>
          <Row>
            <div style={{ width: "100%" }} className="d-flex flex-wrap">
              {zonesDrop.map((item, index) => {
                return (
                  <div className="age-div" key={index}>
                    <Form.Check
                      type="radio"
                      disabled={true}
                      label={item.label}
                      name="zone"
                      value={item.value}
                      className="gender"
                      id={item.optionName}
                      onChange={() => {
                        setzone(item.value);
                      }}
                      checked={zone === item.value}
                    />
                  </div>
                );
              })}
            </div>
          </Row>
        </Row>

        {/* ward Name  */}
        {/* <Row className="d-flex flex-column mt-5">
          <h4 className="section-heading">
            * PCMC Ward name where you want to suggest work
          </h4>
          <h5 className="section-heading">
          मनपा वॉर्ड नाव ज्या मध्ये काम सुचवायचे आहे
          </h5>
          <h6>
            (वॉर्ड नंबर माहिती नसल्यास सारथी हेल्पलाइनवर खात्री करावी -
            ८८८८००६६६६ किंवा या लिंकवर क्लिक करा
            <a href="https://www.pcmcindia.gov.in/marathi/ward_info_m.php">
              {" "}
              https://www.pcmcindia.gov.in/marathi/ward_info_m.php
            </a>{" "}
            )
          </h6>
          <Row>
          <Col md={6} className="d-flex align-items-center mt-2">
            <Form.Label className="form-label">Name/ नाव </Form.Label>
            <Form.Control
              value={wardName}
              className="me-3"
              disabled={true}
            />
          </Col>
          </Row>
        </Row> */}

        {/* ward No  */}
        <Row className="d-flex flex-column mt-5">
          <h4 className="section-heading">
            * PCMC Ward number where you want to suggest work
          </h4>
          <h5 className="section-heading">
            मनपा वॉर्ड नंबर ज्या मध्ये काम सुचवायचे आहे
          </h5>
          <h6>
            (वॉर्ड नंबर माहिती नसल्यास सारथी हेल्पलाइनवर खात्री करावी -
            ८८८८००६६६६ किंवा या लिंकवर क्लिक करा
            <a href="https://www.pcmcindia.gov.in/marathi/ward_info_m.php">
              {" "}
              https://www.pcmcindia.gov.in/marathi/ward_info_m.php
            </a>{" "}
            )
          </h6>
          <Row>
            <Col md={4} className="mt-2">
              <Form.Label>* Ward/वॉर्ड </Form.Label>
              <Typeahead
                id="basic-typeahead-single"
                labelKey="wardno"
                selected={ward}
                disabled={true}
                onChange={(item) => {
                  if (item.length > 0) {
                    setward(item);
                    setWardName(item[0].wardno);
                    setarea([]);
                  } else {
                    setward([]);
                    setWardName("");
                  }
                }}
                options={wardDrop}
                placeholder="Choose a ward..."
              />
            </Col>
          </Row>
        </Row>

        {/*Vertical */}
        <Row className="d-flex flex-column mt-5">
          <h4 className="section-heading">
            <span style={{ color: "red" }}> *</span> Select Your suggested work
            Vertical/
          </h4>
          {surveyError.selectedVertical ? (
            <span className="errormsg">{surveyError.selectedVertical}</span>
          ) : null}
          <div style={{ width: "100%" }} className="d-flex flex-wrap">
            {verticalArray.map((row, index) => {
              return (
                <div className="vertical-div" key={index}>
                  <Form.Check
                    type="radio"
                    label={row.label}
                    name="vertical"
                    value={row.value}
                    className="gender"
                    id={row.label}
                    checked={selectedVertical === row.value}
                    onChange={(e) => {
                      setSelectedVertical(row.value);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </Row>

        <center>
          <Button
            className="submit-button mt-3"
            disabled={limitLoader}
            onClick={() => {
              handleNext();
            }}
          >
            Next
          </Button>
        </center>
      </div>
    </Container>
  );
};

export default InitialSurvey;
