import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  formData:{},
  isFormSubmit:false
};

const surveyDataSlice = createSlice({
  name: 'surveyData',
  initialState,
  reducers:{
    setFormData(state,action){
      state.formData = { ...state.formData,...action.payload }
    }
    ,
    resetFormData(state,action){
      state.formData = {}
    },
    setFormSubmit(state,action){
      state.isFormSubmit = action.payload
    }
  },
});

export const { setFormData,setFormSubmit,resetFormData } = surveyDataSlice.actions;
export default surveyDataSlice.reducer;