import React, { useEffect, useState } from "react";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  resetFormData,
  setFormData,
  setFormSubmit,
} from "../Store/Slice/surveyDataSlice";

const MaxLimit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNewForm = () => {
    dispatch(resetFormData({}));
    dispatch(setFormSubmit(false));
    navigate("/survey-page");
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="button-container">
        <h6>This Mobile Number has Already Submitted maximum responses!</h6>
        <h6>या मोबाईल क्रमांकाने आधीच जास्तीत जास्त प्रतिसाद सबमिट केले आहेत !</h6>
        <Button
          className="submit-button mt-3 me-2"
          onClick={() => {
            handleNewForm();
          }}
        >
          Start With New Mobile Number
        </Button>
      </div>
    </div>
  );
};

export default MaxLimit;
