import React, { useEffect, useState } from "react";
import { Button, Form, FormCheck, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setFormData } from "../Store/Slice/surveyDataSlice";

const VerticalDataPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formData = useSelector((state) => state.surveyData?.formData);

  const [selectedVericalOption, setSelectedVericalOption] = useState("");
  const [specifyWork, setSpecifyWork] = useState("");
  const [showVertical, setShowVertical] = useState("");
  const [ammenityOptions, setAmenityOption] = useState("");
  const [error, setErrors] = useState({});

  const verticalData = [
    {
      verticalName: "1: Roads/ रस्ते",
      verticalValue: "Roads/ रस्ते",
      verticalOptions: [
        {
          optionName: "Potholes/ रस्त्यावरील खड्डे",
          optionValue: "Potholes/ रस्त्यावरील खड्डे",
        },
        {
          optionName: "Repair of Existing Road/ रस्त्याची दुरुस्ती",
          optionValue: "Repair of Existing Road/ रस्त्याची दुरुस्ती",
        },
        {
          optionName: "New Roads to be laid/ नवीन रस्त्यांची निर्मिती",
          optionValue: "New Roads to be laid/ नवीन रस्त्यांची निर्मिती",
        },
        {
          optionName: "Other/ इतर",
          optionValue: "Other/ इतर",
        },
      ],
    },
    {
      verticalName: "2: Waste Management/ कचरा व्यवस्थापन",
      verticalValue: "Waste Management/ कचरा व्यवस्थापन",
      verticalOptions: [
        {
          optionName: "Require/Improve door to door Collection of segregated waste/ विलगीकरण केलेल्या कचऱ्याचे घरोघरी जाऊन संकलन करण्याच्या प्रक्रियेमध्ये सुधारणा करणे आवश्यक आहे.",
          optionValue: "Require/Improve door to door Collection of segregated waste/ विलगीकरण केलेल्या कचऱ्याचे घरोघरी जाऊन संकलन करण्याच्या प्रक्रियेमध्ये सुधारणा करणे आवश्यक आहे.",
        },
        {
          optionName: "Clear/Remove Open-garbage points/ खुल्या जागांवरील कचरा टाकणे बंद करणे",
          optionValue: "Clear/Remove Open-garbage points/ खुल्या जागांवरील कचरा टाकणे बंद करणे",
        },
        {
          optionName: "Regularise Road Sweeping/ रस्त्यांची नियमित साफसफाई करणे",
          optionValue: "Regularise Road Sweeping/ रस्त्यांची नियमित साफसफाई करणे",
        },
        {
          optionName: "Other/ इतर",
          optionValue: "Other/ इतर",
        },
      ],
    },
    {
      verticalName: "3: Water Supply/ पाणीपुरवठा",
      verticalValue: "Water Supply/ पाणीपुरवठा",
      verticalOptions: [
        {
          optionName: "Irregular water supply/ अनियमित पाणीपुरवठा",
          optionValue: "Irregular water supply/ अनियमित पाणीपुरवठा",
        },
        {
          optionName: "No water supply/ पाणी पुरवठा होत नाही",
          optionValue: "No water supply/ पाणी पुरवठा होत नाही",
        },
        {
          optionName: "Other/ इतर",
          optionValue: "Other/ इतर",
        },
      ],
    },
    {
      verticalName: "4: Storm Water Drainage/ पावसाळी पाण्याचा निचरा",
      verticalValue: "Storm Water Drainage/ पावसाळी पाण्याचा निचरा",
      verticalOptions: [
        {
          optionName: "Require New Underground Drainage/ नवीन भूमिगत गटारांची आवश्यकता",
          optionValue: "Require New Underground Drainage/ नवीन भूमिगत गटारांची आवश्यकता",
        },
        {
          optionName: "Improve existing drains/ सध्या अस्तित्वात असलेल्या गटारांची सुधारणा",
          optionValue: "Improve existing drains/ सध्या अस्तित्वात असलेल्या गटारांची सुधारणा",
        },
        {
          optionName: "Other/ इतर",
          optionValue: "Other/ इतर",
        },
      ],
    },
    {
      verticalName: "5: Sewage management / मैला व्यवस्थापन",
      verticalValue: "Sewage management / मैला व्यवस्थापन",
      verticalOptions: [
        {
          optionName: "Require New Underground Drainage/ नवीन भूमिगत गटारांची निर्मिती",
          optionValue: "Require New Underground Drainage/ नवीन भूमिगत गटारांची निर्मिती",
        },
        {
          optionName: "Improve existing drains/ सध्या अस्तित्वात असलेल्या गटारांमध्ये सुधारणा",
          optionValue: "Improve existing drains/ सध्या अस्तित्वात असलेल्या गटारांमध्ये सुधारणा",
        },
        {
          optionName: "Other/ इतर",
          optionValue: "Other/ इतर",
        },
      ],
    },
    {
      verticalName: "6: Streetlights/ रस्त्यावरील दिवे",
      verticalValue: "Streetlights/ रस्त्यावरील दिवे",
      verticalOptions: [
        {
          optionName: "Repairing Existing Street Lights/ सध्या अस्तित्वात असलेल्या रस्त्यावरील दिव्यांची दुरुस्ती",
          optionValue: "Repairing Existing Street Lights/ सध्या अस्तित्वात असलेल्या रस्त्यावरील दिव्यांची दुरुस्ती",
        },
        {
          optionName: "Installation of New Street Light/ नवीन पथदिवे लावणे",
          optionValue: "Installation of New Street Light/ नवीन पथदिवे लावणे",
        },
        {
          optionName: "Other/ इतर",
          optionValue: "Other/ इतर",
        },
      ],
    },
    {
      verticalName: "7: Footpaths/ पदपथ",
      verticalValue: "Footpaths/ पदपथ",
      verticalOptions: [
        {
          optionName: "Repair Broken Footpaths/ खराब झालेल्या पदपथांची दुरुस्ती",
          optionValue: "Repair Broken Footpaths/ खराब झालेल्या पदपथांची दुरुस्ती",
        },
        {
          optionName: "Provision of New Footpaths/ नवीन पदपथांसाठी तरतूद करणे",
          optionValue: "Provision of New Footpaths/ नवीन पदपथांसाठी तरतूद करणे",
        },
        {
          optionName: "Other/ इतर",
          optionValue: "Other/ इतर",
        },
      ],
    },
    {
      verticalName: "8: Public Toilets/ सार्वजनिक शौचालय",
      verticalValue: "Public Toilets/ सार्वजनिक शौचालये",
      verticalOptions: [
        {
          optionName: "Repair/Clean Existing Public Toilets/ अस्तित्वात असलेल्या सार्वजनिक शौचालयांची दुरुस्ती आणि साफसफाई करणे",
          optionValue: "Repair/Clean Existing Public Toilets/ अस्तित्वात असलेल्या सार्वजनिक शौचालयांची दुरुस्ती आणि साफसफाई करणे",
        },
        {
          optionName: "Provide New Public Toilets/ नवीन सार्वजनिक शौचालये उपलब्ध करणे",
          optionValue: "Provide New Public Toilets/ नवीन सार्वजनिक शौचालये उपलब्ध करणे",
        },
        {
          optionName: "Other/ इतर",
          optionValue: "Other/ इतर",
        },
      ],
    },
    {
      verticalName: "9: Parks and Playgrounds/ उद्याने आणि खेळाची मैदाने",
      verticalValue: "Parks and Playgrounds/ उद्याने आणि खेळाची मैदाने",
      verticalOptions: [
        {
          optionName: "Clean Existing Parks/Playgrounds/ अस्तित्वात असलेल्या उद्यानांची आणि मैदानांची दुरुस्ती आणि साफसफाई करणे",
          optionValue: "Clean Existing Parks/Playgrounds/ अस्तित्वात असलेल्या उद्यानांची आणि मैदानांची दुरुस्ती आणि साफसफाई करणे",
        },
        {
          optionName: "Provide New Playgrounds/ नवीन खेळाची मैदाने विकसित करणे",
          optionValue: "Provide New Playgrounds/ नवीन खेळाची मैदाने विकसित करणे",
        },
        {
          optionName: "Provide New Parks/ नवीन उद्याने विकसित करणे",
          optionValue: "Provide New Parks/ नवीन उद्याने विकसित करणे",
        },
        {
          optionName: "Other/ इतर",
          optionValue: "Other/ इतर",
        },
      ],
    },
    {
      verticalName: "10: EV charging / ईव्ही चार्जिंग",
      verticalValue: "EV charging / ईव्ही चार्जिंग",
      verticalOptions: [
        {
          optionName: "Provide new EV charging station/ नवीन ईव्ही चार्जिंग स्टेशनची निर्मिती",
          optionValue: "Provide new EV charging station/ नवीन ईव्ही चार्जिंग स्टेशनची निर्मिती",
        },
        {
          optionName: "Repair of existing EV charging station/ सध्या अस्तित्वात असलेल्या ईव्ही चार्जिंग स्टेशनमध्ये सुधारणा",
          optionValue: "Repair of existing EV charging station/ सध्या अस्तित्वात असलेल्या ईव्ही चार्जिंग स्टेशनमध्ये सुधारणा",
        },
        {
          optionName: "Other/ इतर",
          optionValue: "Other/ इतर",
        },
      ],
    },
    {
      verticalName: "11: Bus stops/BRTS / बस स्टॉप्स/बीआरटीएस",
      verticalValue: "Bus stops/BRTS / बस स्टॉप्स/बीआरटीएस",
      verticalOptions: [
        {
          optionName: "Repair of bus stops/terminals / सध्या अस्तित्वात असलेलेले बस थांबे / स्थानके यांमध्ये सुधारणा",
          optionValue: "Repair of bus stops/terminals / सध्या अस्तित्वात असलेलेले बस थांबे / स्थानके यांमध्ये सुधारणा",
        },
        {
          optionName: "Provide new public transport service / नवीन सार्वजनिक वाहतूक सुविधा पुरवणे  ",
          optionValue: "Provide new public transport service / नवीन सार्वजनिक वाहतूक सुविधा पुरवणे  ",
        },
      ],
    },
    {
      verticalName: "12: Health/ आरोग्य",
      verticalValue: "Health/ आरोग्य",
      verticalOptions: [
        {
          optionName: "Provide health care center/ आरोग्य सेवा केंद्र विकसित करणे",
          optionValue: "Provide health care center/ आरोग्य सेवा केंद्र विकसित करणे",
        },
        {
          optionName: "Repair of existing infrastructure/ उपलब्ध पायाभूत सेवासुविधांची दुरुस्ती",
          optionValue: "Repair of existing infrastructure/ उपलब्ध पायाभूत सेवासुविधांची दुरुस्ती",
        },
        {
          optionName: "Other/ इतर",
          optionValue: "Other/ इतर",
        },
      ],
    },
    {
      verticalName: "13: Sports/ क्रीडा",
      verticalValue: "Sports/ क्रीडा",
      verticalOptions: [
        {
          optionName: "Provide new sports complex/ नवीन क्रीडा संकुल विकसित करणे",
          optionValue: "Provide new sports complex/ नवीन क्रीडा संकुल विकसित करणे",
        },
        {
          optionName: "Need improvement in the complex/ सध्या अस्तित्वात असलेल्या क्रीडा संकुलामध्ये सुधारणा करणे",
          optionValue: "Need improvement in the complex/ सध्या अस्तित्वात असलेल्या क्रीडा संकुलामध्ये सुधारणा करणे",
        },
        {
          optionName: "Other/ इतर",
          optionValue: "Other/ इतर",
        },
      ],
    },
    {
      verticalName: "14: Parking Facility/ पार्किंग सुविधा",
      verticalValue: "Parking Facility/ पार्किंग सुविधा",
      verticalOptions: [
        {
          optionName: "Provide new parking facility/ नवीन पार्किंग सुविधेची निर्मिती ",
          optionValue: "Provide new parking facility/ नवीन पार्किंग सुविधेची निर्मिती ",
        },
        {
          optionName: "Need improvement in the existing parking facility/ सध्या अस्तित्वात असलेल्या पार्किंग सुविधेमध्ये सुधारणा ",
          optionValue: "Need improvement in the existing parking facility/ सध्या अस्तित्वात असलेल्या पार्किंग सुविधेमध्ये सुधारणा ",
        },
        {
          optionName: "Other/ इतर",
          optionValue: "Other/ इतर",
        },
      ],
    },
    {
      verticalName: "15: Cycle tracks/ सायकल ट्रॅक्स",
      verticalValue: "Cycle tracks/ सायकल ट्रॅक्स",
      verticalOptions: [
        {
          optionName: "Provide new cycle track facility/ नवीन सायकल ट्रॅक्स सुविधेची निर्मिती ",
          optionValue: "Provide new cycle track facility/ नवीन सायकल ट्रॅक्स सुविधेची निर्मिती ",
        },
        {
          optionName: "Need improvement in the existing cycling facility/ सध्या अस्तित्वात असलेल्या सायकल ट्रॅक्स सुविधेमध्ये सुधारणा  ",
          optionValue: "Need improvement in the existing cycling facility/ सध्या अस्तित्वात असलेल्या सायकल ट्रॅक्स सुविधेमध्ये सुधारणा  ",
        },
        {
          optionName: "Other/ इतर",
          optionValue: "Other/ इतर",
        },
      ],
    },
    {
      verticalName: "16: Traffic Lights / ट्रॅफिक लाईट",
      verticalValue: "Traffic Lights / ट्रॅफिक लाईट",
      verticalOptions: [
        {
          optionName: "Provide traffic signal facility/ ट्रॅफिक सिग्नल सुविधेची निर्मिती ",
          optionValue: "Provide traffic signal facility/ ट्रॅफिक सिग्नल सुविधेची निर्मिती ",
        },
        {
          optionName: "Need improvement in the existing traffic signal facility/ सध्या अस्तित्वात असलेल्या ट्रॅफिक सिग्नल सुविधेमध्ये सुधारणा  ",
          optionValue: "Need improvement in the existing traffic signal facility/ सध्या अस्तित्वात असलेल्या ट्रॅफिक सिग्नल सुविधेमध्ये सुधारणा  ",
        },
        {
          optionName: "Other/ इतर",
          optionValue: "Other/ इतर",
        },
      ],
    },
    {
      verticalName: "17: Sign and Signages / आकाशचिन्ह",
      verticalValue: "Sign and Signages / आकाशचिन्ह",
      verticalOptions: [
        {
          optionName: "Provide new signage/ नवीन आकाशचिन्ह सुविधेची निर्मिती ",
          optionValue: "Provide new signage/ नवीन आकाशचिन्ह सुविधेची निर्मिती ",
        },
        {
          optionName: "Need improvement in the existing signage/ सध्या अस्तित्वात असलेल्या आकाशचिन्ह सुविधेमध्ये सुधारणा  ",
          optionValue: "Need improvement in the existing signage/ सध्या अस्तित्वात असलेल्या आकाशचिन्ह सुविधेमध्ये सुधारणा  ",
        },
        {
          optionName: "Other/ इतर",
          optionValue: "Other/ इतर",
        },
      ],
    },
    {
      verticalName: "18: Water logging / पाणी साचणे ",
      verticalValue: "Water logging / पाणी साचणे",
      verticalOptions: [
        {
          optionName: "Water Logging/Flood prone area/ पाणी साचणे/पूर प्रवण क्षेत्र ",
          optionValue: "Water Logging/Flood prone area/ पाणी साचणे/पूर प्रवण क्षेत्र ",
        },
        {
          optionName: "Other/ इतर",
          optionValue: "Other/ इतर",
        },
      ],
    },
    {
      verticalName:
        "19: Public Amenities (Street Furniture/Swimming pool/Library/Auditorium etc) / सार्वजनिक सुविधा (रस्त्याशी संबंधित फर्निचर/जलतरण तलाव/वाचनालय/ नाट्यगृह)",
      verticalValue:
        "Public Amenities / सार्वजनिक सुविधा (रस्त्याशी संबंधित फर्निचर/जलतरण तलाव/वाचनालय/ नाट्यगृह)",
      verticalOptions: [
        {
          optionName:
            "Provide new selected public amenity/ नवीन सार्वजनिक सुविधेची निर्मिती करा",
          optionValue:
            "Provide new selected public amenity/ नवीन सार्वजनिक सुविधेची निर्मिती करा",
        },
        {
          optionName:
            "Need improvement in the selected public amenity/ सध्या अस्तित्वात असलेल्या सार्वजनिक सुविधेमध्ये सुधारणा  ",
          optionValue:
            "Need improvement in the selected public amenity/ सध्या अस्तित्वात असलेल्या सार्वजनिक सुविधेमध्ये सुधारणा  ",
        },
        {
          optionName: "Other/ इतर",
          optionValue: "Other/ इतर",
        },
      ],
    },
    {
      verticalName: "20: Education / शिक्षण",
      verticalValue: "Education / शिक्षण",
      verticalOptions: [
        {
          optionName: "Need improvement in the existing school/ विद्यमान शाळेमध्ये सुधारणा आवश्यक आहे ",
          optionValue: "Need improvement in the existing school/ विद्यमान शाळेमध्ये सुधारणा आवश्यक आहे ",
        },
        {
          optionName: "New school development/ नवीन शाळा बांधणे",
          optionValue: "New school development/ नवीन शाळा बांधणे",
        },
        {
          optionName: "Other/ इतर",
          optionValue: "Other/ इतर",
        },
      ],
    },
    {
      verticalName: "21: Other/ इतर",
      verticalValue: "Other/ इतर",
      verticalOptions: [
      ],
    },

  ];

  const AmenityOptions = [
    {
      label: "Street Furniture/ रस्त्याशी संबंधित फर्निचर",
      value: "Street Furniture/ रस्त्याशी संबंधित फर्निचर",
    },
    { label: "Swimming pool/ जलतरण तलाव", value: "Swimming pool/ जलतरण तलाव" },
    { label: "Library/ वाचनालय", value: "Library/ वाचनालय" },
    { label: "Auditorium/ नाट्यगृह", value: "Auditorium/ नाट्यगृह" },
    { label: "Other/ इतर", value: "Other/ इतर" },
  ];

  useEffect(() => {
    if (formData) {
      populateReduxData();
    }
  }, []);

  const populateReduxData = () => {
    if (formData.selectedVertical) {
      let item = verticalData.filter(
        (row) => row.verticalValue === formData.selectedVertical
      );
      setShowVertical(item[0]);
    }

    if (formData.selectedVericalOption) {
      setSelectedVericalOption(formData.selectedVericalOption);
    }

    if (formData.specifyWork) {
      setSpecifyWork(formData.specifyWork);
    }

    if (formData.ammenityOptions) {
      setAmenityOption(formData.ammenityOptions);
    }
  };

  const isValidate = () => {
    let errors = {};

    if ( showVertical.verticalValue !== 'Other/ इतर' && !selectedVericalOption) {
      errors.selectedVericalOption = "Please Select One of the Vertical Option";
    } else if ( selectedVericalOption &&
      showVertical.verticalOptions.filter(
        (row) => row.optionValue === selectedVericalOption
      ).length === 0
    ) {
      errors.selectedVericalOption = "Please Select One of the Vertical Option";
    }

    if (
      showVertical.verticalValue ===
        "Public Amenities / सार्वजनिक सुविधा (रस्त्याशी संबंधित फर्निचर/जलतरण तलाव/वाचनालय/ नाट्यगृह)" &&
      !ammenityOptions
    ) {
      errors.ammenityOptions = "Please Select One Ammenity Options.";
    }

    console.log(errors)

    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      alert("Please Add Needed Information");
    }

    return Object.keys(errors).length === 0;
  };

  const handleNext = () => {
    if (isValidate()) {
      dispatch(
        setFormData({
          selectedVericalOption: selectedVericalOption,
          specifyWork: specifyWork,
          ammenityOptions: ammenityOptions,
        })
      );
      navigate("/survey-submit");
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="form-container">
        {showVertical.verticalValue ===
        "Public Amenities / सार्वजनिक सुविधा (रस्त्याशी संबंधित फर्निचर/जलतरण तलाव/वाचनालय/ नाट्यगृह)" ? (
          <Row className="d-flex flex-column mt-5">
            <h4 className="section-heading">
            <span style={{ color:'red' }}> *</span> Public Amenities Select from the following: (Street
              Furniture/Swimming pool/Library/Auditorium/others) 
            </h4>
            <h5 className="section-heading">
              सार्वजनिक सुविधा , खालीलपैकी एक निवडा (रस्त्याशी संबंधित
              फर्निचर/जलतरण तलाव/वाचनालय/ नाट्यगृह) 
            </h5>
            {error.ammenityOptions ? (
              <span className="errormsg">{error.ammenityOptions}</span>
            ) : null}
            <Row>
              <div style={{ width: "100%" }} className="d-flex flex-wrap">
                {AmenityOptions.map((item, index) => {
                  return (
                    <div className="age-div" key={index}>
                      <Form.Check
                        type="radio"
                        label={item.label}
                        name="zone"
                        value={item.value}
                        className="gender"
                        id={item.label}
                        onChange={() => {
                          setAmenityOption(item.value);
                        }}
                        checked={ammenityOptions === item.value}
                      />
                    </div>
                  );
                })}
              </div>
            </Row>
          </Row>
        ) : null}

        {/* Vertical Options */}
        {showVertical ? (
          <Row className="d-flex flex-column mt-5">
            <h4 className="section-heading"> {
             showVertical.verticalName !== 'Other/ इतर' ? <span style={{ color:'red' }}> *</span> : null
              } {showVertical.verticalName}</h4>
            {error.selectedVericalOption ? (
              <span className="errormsg">{error.selectedVericalOption}</span>
            ) : null}
            <div style={{ marginLeft: 20 }}>
              {showVertical.verticalOptions.map((item, index) => {
                return (
                  <FormCheck
                    key={index}
                    type="radio"
                    label={item.optionName}
                    name="vertical"
                    value={item.optionValue}
                    className="gender"
                    id={item.optionName}
                    onChange={() => {
                      setSelectedVericalOption(item.optionValue);
                    }}
                    checked={selectedVericalOption === item.optionValue}
                  />
                );
              })}
            </div>
          </Row>
        ) : (
          ""
        )}

        {/* Work Description */}
        <Row className="mt-5">
          <Form.Group controlId="formBasicDescription">
            <Form.Label className="work-label">
              Specify Your Work Here (specify the location/landmark)
            </Form.Label>
            <Form.Label className="work-label">
              तुमच्या सूचना येथे नमूद करा (स्थान/लँडमार्क नमूद करायला विसरू नका)
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={8}
              value={specifyWork}
              onChange={(e) => setSpecifyWork(e.target.value)}
            />
          </Form.Group>
        </Row>

        <center>
          <Button
            className="submit-button mt-3 me-2"
            onClick={() => {
              window.history.back();
            }}
          >
            Prev
          </Button>
          <Button
            className="submit-button mt-3 me-2"
            onClick={() => {
              handleNext();
            }}
          >
            Next
          </Button>
        </center>
      </div>
    </div>
  );
};

export default VerticalDataPage;
