import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import surveyDataSlice from './Slice/surveyDataSlice';

const reducers = combineReducers({
    surveyData : surveyDataSlice
})

// const rootReducer = (state, action) => {
//     if(action.type === "userData/setLogout"){
//       storage.removeItem('persist:root')
//       localStorage.clear();
//       return reducers(undefined, action)
//     }  

//     return reducers(state, action);
// }

const persistConfig = {
  key:'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
